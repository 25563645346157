import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Dropdown,
  Form,
  FormField,
  TextArea,
  FormGroup
} from 'semantic-ui-react'

const CardDischargeSummaryUX = (props: any) => {
    return(
      <div
        id="CardDischargeSummaryUX-div-0"
        style={{}}>
        <div
          className="accordion ui fluid blue raised segment"
          id="CardDischargeSummaryUX-div-1"
          style={{boxShadow: "none"}}>
          
          <label
            id="CardDischargeSummaryUX-label-2"
            style={{fontSize: "1.25rem", fontWeight: "bold"}}>
            Discharge Summary
          </label>
          <div
            className="ui divider"
            id="CardDischargeSummaryUX-div-3">
            
          </div>
          <div
            id="CardDischargeSummaryUX-div-5"
            style={{display: "flex", justifyContent: "flex-end", marginTop: "15px"}}>
            
            <Button
              color={props.id ? "yellow" : "green"}
              id="CardDischargeSummaryUX-Button-6"
              onClick={props.onSave}>
              {props.id ? "แก้ไข" : "บันทึก"}
            </Button>
          </div>
          <div
            id="CardDischargeSummaryUX-div-7"
            style={{ display: "flex", alignItems: "center" }}>
            
            <label
              id="CardDischargeSummaryUX-label-8"
              style={{marginRight: "15px"}}>
              Plan Discharge
            </label>
            <div
              id="CardDischargeSummaryUX-div-9"
              style={{marginRight: "15px"}}>
              {props.DateElement}
            </div>
            <Dropdown
              id="CardDischargeSummaryUX-Dropdown-10"
              name="planDischargeType"
              onChange={props.onChangeValue}
              options={props.planDischargeTypeOptions}
              selection={true}
              value={props.planDischargeType}>
            </Dropdown>
          </div>
          <div
            id="CardDischargeSummaryUX-div-11">
            {props.SubProvisionDiag}
          </div>
          <div
            id="CardDischargeSummaryUX-div-12">
            {props.SubDRG}
          </div>
          <div
            className="ui divider"
            id="CardDischargeSummaryUX-div-13">
            
          </div>
          <div
            id="CardDischargeSummaryUX-div-16">
            
            <Form
              id="CardDischargeSummaryUX-Form-17">
              <FormField
                id="CardDischargeSummaryUX-FormField-18">
                <label
                  id="CardDischargeSummaryUX-label-19">
                  Brief History and Physical Findings
                </label>
                <TextArea
                  id="CardDischargeSummaryUX-TextArea-20"
                  name="brief"
                  onChange={props.onChangeValue}
                  rows={8}
                  value={props.brief}>
                </TextArea>
              </FormField>
              <FormField
                id="CardDischargeSummaryUX-FormField-21">
                <label
                  id="CardDischargeSummaryUX-label-26">
                  Hospital Course
                </label>
                <TextArea
                  id="CardDischargeSummaryUX-TextArea-33"
                  name="course"
                  onChange={props.onChangeValue}
                  rows={8}
                  value={props.course}>
                </TextArea>
              </FormField>
              <FormField
                id="CardDischargeSummaryUX-FormField-22">
                <label
                  id="CardDischargeSummaryUX-label-27">
                  Prescription / Advice on Discharge
                </label>
                <TextArea
                  id="CardDischargeSummaryUX-TextArea-34"
                  name="condition"
                  onChange={props.onChangeValue}
                  rows={8}
                  value={props.condition}>
                </TextArea>
              </FormField>
              <FormField
                id="CardDischargeSummaryUX-FormField-23">
                <label
                  id="CardDischargeSummaryUX-label-28">
                  Follow up plan
                </label>
                <TextArea
                  id="CardDischargeSummaryUX-TextArea-35"
                  name="followup"
                  onChange={props.onChangeValue}
                  value={props.followup}>
                </TextArea>
              </FormField>
              <FormField
                id="CardDischargeSummaryUX-FormField-24">
                <label
                  id="CardDischargeSummaryUX-label-29">
                  Discharge status
                </label>
                <Dropdown
                  clearable={true}
                  id="CardDischargeSummaryUX-Dropdown-32"
                  name="dischargeStatus"
                  onChange={props.onChangeValue}
                  options={props.dischargeStatusOptions}
                  selection={true}
                  value={props.dischargeStatus}>
                </Dropdown>
              </FormField>
              <FormField
                id="CardDischargeSummaryUX-FormField-25"
                style={{ display: props.hideReadmissionType ? "none" : "" }}>
                <label
                  id="CardDischargeSummaryUX-label-30">
                  Readmission Type
                </label>
                <Dropdown
                  clearable={true}
                  id="CardDischargeSummaryUX-Dropdown-31"
                  name="readmissionType"
                  onChange={props.onChangeValue}
                  options={props.readmissionTypeOptions}
                  selection={true}
                  value={props.readmissionType}>
                </Dropdown>
              </FormField>
              <FormGroup
                id="CardDischargeSummaryUX-FormGroup-37"
                inline={true}>
                <FormField
                  id="CardDischargeSummaryUX-FormField-38"
                  width={2}>
                  <label
                    id="CardDischargeSummaryUX-label-42">
                    Attending Physician
                  </label>
                </FormField>
                <FormField
                  id="CardDischargeSummaryUX-FormField-39"
                  width={6}>
                  <div
                    id="CardDischargeSummaryUX-div-44"
                    style={{width:"100%"}}>
                    {props.physicianSearchBox}
                  </div>
                </FormField>
                <FormField
                  id="CardDischargeSummaryUX-FormField-40"
                  width={2}>
                  <label
                    id="CardDischargeSummaryUX-label-43">
                    {props.showResident ? "Resident": ""}
                  </label>
                </FormField>
                <FormField
                  id="CardDischargeSummaryUX-FormField-41"
                  width={5}>
                  <div
                    id="CardDischargeSummaryUX-div-45"
                    style={{width:"100%"}}>
                    {props.residentSearchBox}
                  </div>
                </FormField>
                <FormField
                  id="CardDischargeSummaryUX-FormField-46">
                </FormField>
              </FormGroup>
            </Form>
          </div>
          <div
            id="CardDischargeSummaryUX-div-36">
            {props.LoadingElement}
          </div>
        </div>
      </div>
    )
}


export default CardDischargeSummaryUX

export const screenPropsDefault = {"by":"order","title":"MEDICAL FEE","titleDescription":"ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"}

/* Date Time : Tue Mar 18 2025 12:09:21 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-div-0"
        },
        "style": {
          "type": "code",
          "value": "{}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "accordion ui fluid blue raised segment"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-div-1"
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Discharge Summary"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-label-2"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.25rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-div-3"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-div-5"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", marginTop: \"15px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.id ? \"แก้ไข\" : \"บันทึก\""
        },
        "color": {
          "type": "code",
          "value": "props.id ? \"yellow\" : \"green\""
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-Button-6"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSave"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-div-7"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "Plan Discharge"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-label-8"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"15px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DateElement"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-div-9"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"15px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Dropdown",
      "parent": 7,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-Dropdown-10"
        },
        "name": {
          "type": "value",
          "value": "planDischargeType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.planDischargeTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.planDischargeType"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SubProvisionDiag"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-div-11"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SubDRG"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-div-12"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-div-13"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-div-16"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Form",
      "parent": 16,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-Form-17"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 17,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-FormField-18"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Brief History and Physical Findings"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-label-19"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "TextArea",
      "parent": 18,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-TextArea-20"
        },
        "name": {
          "type": "value",
          "value": "brief"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "rows": {
          "type": "code",
          "value": "8"
        },
        "value": {
          "type": "code",
          "value": "props.brief"
        }
      },
      "seq": 20,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 17,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-FormField-21"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 17,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-FormField-22"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 17,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-FormField-23"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 17,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-FormField-24"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 17,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-FormField-25"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.hideReadmissionType ? \"none\" : \"\" }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "Hospital Course"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-label-26"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "Prescription / Advice on Discharge"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-label-27"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "label",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "Follow up plan"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-label-28"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "label",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "Discharge status"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-label-29"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "Readmission Type"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-label-30"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Dropdown",
      "parent": 25,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-Dropdown-31"
        },
        "name": {
          "type": "value",
          "value": "readmissionType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.readmissionTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.readmissionType"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Dropdown",
      "parent": 24,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-Dropdown-32"
        },
        "name": {
          "type": "value",
          "value": "dischargeStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.dischargeStatusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.dischargeStatus"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "TextArea",
      "parent": 21,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-TextArea-33"
        },
        "name": {
          "type": "value",
          "value": "course"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "rows": {
          "type": "code",
          "value": "8"
        },
        "value": {
          "type": "code",
          "value": "props.course"
        }
      },
      "seq": 33,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "TextArea",
      "parent": 22,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-TextArea-34"
        },
        "name": {
          "type": "value",
          "value": "condition"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "rows": {
          "type": "code",
          "value": "8"
        },
        "value": {
          "type": "code",
          "value": "props.condition"
        }
      },
      "seq": 34,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "TextArea",
      "parent": 23,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-TextArea-35"
        },
        "name": {
          "type": "value",
          "value": "followup"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.followup"
        }
      },
      "seq": 35,
      "void": true
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.LoadingElement"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-div-36"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormGroup",
      "parent": 17,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-FormGroup-37"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 37,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-FormField-38"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 37,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-FormField-39"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 37,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-FormField-40"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 37,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-FormField-41"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "Attending Physician"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-label-42"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showResident ? \"Resident\": \"\""
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-label-43"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "code",
          "value": "props.physicianSearchBox"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-div-44"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "code",
          "value": "props.residentSearchBox"
        },
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-div-45"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 37,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDischargeSummaryUX-FormField-46"
        }
      },
      "seq": 47,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDischargeSummaryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "by": "order",
    "title": "MEDICAL FEE",
    "titleDescription": "ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"
  },
  "width": 80
}

*********************************************************************************** */
