import React, { useEffect, useMemo } from "react";
import { Dimmer, Loader } from "semantic-ui-react";

import moment from "moment";

// UX
import CardDischargeSummaryUX from "../ADM/CardDischargeSummaryUX";
import SubProvisionDiag from "./SubProvisionDiag";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";

import DateTextBox from "react-lib/apps/common/DateTextBox";
import ModInfo from "react-lib/apps/common/ModInfo";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

import CONFIG from "config/config";

// Const
const MIN_DATE = formatDate(moment());

const CARD_DISCHARGE_SUMMARY = "CardDischargeSummary";

const CARD_SUB_DRG = "CardSubDrg";

const CardDischargeSummary = (props: any) => {
  const intl = useIntl();
  useEffect(() => {
    props.runSequence({
      sequence: "DischargeSummary",
      restart: true,
      card: CARD_DISCHARGE_SUMMARY,
      cardDrg: CARD_SUB_DRG,
    });
    return () => {
      props.onEvent({
        message: "DischargeSummary",
        params: { clear: true },
      });
    };
  }, []);

  const diagnosisTypeOptions = useMemo(() => {
    return (props.masterOptions.diagnosisType || []).filter((item: any) =>
      ["SECONDARY_COMPLICATION", "SECONDARY_OTHER", "EXTERNAL_CAUSE"].includes(item.value)
    );
  }, [props.masterOptions?.diagnosisType]);

  const provisionalTypeOptions = useMemo(() => {
    return (props.masterOptions.diagnosisType || []).filter((item: any) =>
      item.value.startsWith("PROVISIONAL_SECONDARY_")
    );
  }, [props.masterOptions?.diagnosisType]);

  const handleSelectPhysician = (value: any, key: any, data: any) => {
    props.setProp(`DischargeSummarySequence.detail.attending_physician`, {id:value,name_code:data?.name_code});
  };

  const handleSelectResident = (value: any, key: any, data: any) => {
    props.setProp(`DischargeSummarySequence.detail.resident`, {id:value,name_code:data?.name_code});
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "DischargeSummary",
      action: "save",
      card: CARD_DISCHARGE_SUMMARY,
      cardDrg: CARD_SUB_DRG,
    });
  };

  console.log("CardDischargeSummary", props);

  return (
    <div style={{ position: "relative", paddingBottom: "15rem",minWidth: "860px" }}>
      <CardDischargeSummaryUX
        id={props.DischargeSummarySequence?.id || null}
        // Options
        planDischargeTypeOptions={props.masterOptions?.planDischargeType || []}
        dischargeStatusOptions={props.masterOptions?.dischargeStatus || []}
        readmissionTypeOptions={props.masterOptions?.readmissionType || []}
        // Data
        planDischargeType={
          props.DischargeSummarySequence?.planDischargeType || ""
        }
        dischargeStatus={props.DischargeSummarySequence?.dischargeStatus || ""}
        readmissionType={props.DischargeSummarySequence?.readmissionType || ""}
        brief={props.DischargeSummarySequence?.brief || ""}
        course={props.DischargeSummarySequence?.course || ""}
        condition={props.DischargeSummarySequence?.condition || ""}
        followup={props.DischargeSummarySequence?.followup || ""}
        hideReadmissionType={CONFIG.COMPANY === "CU"}
        // Element
        DateElement={
          <DateTextBox
            value={props.DischargeSummarySequence?.datePlanDischarge}
            minDate={MIN_DATE}
            onChange={(date: string) =>
              props.setProp("DischargeSummarySequence.datePlanDischarge", date)
            }
          />
        }
        SubProvisionDiag={
          <SubProvisionDiag
            // Function
            runSequence={props.runSequence}
            setProp={props.setProp}
            // data
            provisionalItems={
              props.DischargeSummarySequence?.provisionalItems || []
            }
            diagnosisItems={
              props.DischargeSummarySequence?.diagnosisItems || []
            }
            procedureItems={
              props.DischargeSummarySequence?.procedureItems || []
            }
            primaryText2="PRINCIPAL DIAGNOSIS"
            // options
            diagnosisTypeOptions={diagnosisTypeOptions}
            provisionalTypeOptions={provisionalTypeOptions}
            languageUX={props.languageUX}
          />
        }
        // SubDRG={
        //   <SubDRG
        //     error={props.DischargeSummarySequence?.drgErrorMsg}
        //     drg={props.DischargeSummarySequence?.drgDetail?.drg || ""}
        //     rw={props.DischargeSummarySequence?.drgDetail?.rw || ""}
        //     adj_rw={props.DischargeSummarySequence?.drgDetail?.adj_rw || ""}
        //     loading={!!props.loadingStatus?.[CARD_SUB_DRG]}
        //   />
        // }
        LoadingElement={
          <Dimmer
            active={!!props.loadingStatus?.[CARD_DISCHARGE_SUMMARY]}
            inverted
          >
            <Loader inverted>Loading</Loader>
          </Dimmer>
        }
        physicianSearchBox={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            id="DiscAttendingPhysician"
            type="Doctor"
            style={{ width: "100%" }}
            boxStyle={{width: "100%"}}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            upward={true}
            inline
            defaultText={props.DischargeSummarySequence?.detail?.attending_physician?.name_code || ""}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={props.DischargeSummarySequence?.detail?.attending_physician?.id || null}
            setSelectedItem={handleSelectPhysician}
          />
        }
        showResident={CONFIG.COMPANY === "CU"}
        residentSearchBox={
          <>
            {CONFIG.COMPANY === "CU" && 
              <SearchBoxDropdown
                onEvent={props.onEvent}
                // config
                id="DiscResident"
                type="Doctor"
                style={{ width: "100%" }}
                boxStyle={{width: "100%"}}
                fluid={true}
                useWithKey={true}
                icon="search"
                limit={20}
                upward={true}
                inline
                defaultText={props.DischargeSummarySequence?.detail?.resident?.name_code || ""}
                // Select
                searchedItemListWithKey={props.searchedItemListWithKey}
                selectedItem={props.DischargeSummarySequence?.detail?.resident?.id || null}
                setSelectedItem={handleSelectResident}
              />
            }
          </>
        }
        // callback
        onChangeValue={(e: any, v: any) =>
          props.setProp(`DischargeSummarySequence.${v.name}`, v.value)
        }
        onSave={handleSave}
        languageUX={props.languageUX}
      />

      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_DISCHARGE_SUMMARY}`, null);
        }}
        error={props.errorMessage?.[CARD_DISCHARGE_SUMMARY]}
        success={null}
        languageUX={props.languageUX}
      />

      <ModInfo
        open={!!props.successMessage?.[CARD_DISCHARGE_SUMMARY]}
        titleColor="green"
        titleName={intl.formatMessage({ id: "บันทึกสำเร็จ" })}
        onApprove={() =>
          props.setProp(`successMessage.${CARD_DISCHARGE_SUMMARY}`, null)
        }
        onClose={() =>
          props.setProp(`successMessage.${CARD_DISCHARGE_SUMMARY}`, null)
        }
        closeOnDimmerClick
      />
    </div>
  );
};

export default CardDischargeSummary;
