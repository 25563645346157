import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown,
  Button,
  Form,
  FormGroup,
  FormField,
  Input,
  Checkbox
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardIntraHospitalTransferFormUX = (props: any) => {
    return(
      <div
        className="--override-disabled --grey"
        id="CardIntraHospitalTransferFormUX-div-0"
        style={{width: "100%", height: "100%",padding: "10px"}}>
        <div
          id="CardIntraHospitalTransferFormUX-div-1"
          style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>

          <div
            id="CardIntraHospitalTransferFormUX-div-6"
            style={{padding: "0px 15px"}}>
            From
          </div>
          <Dropdown
            id="CardIntraHospitalTransferFormUX-Dropdown-8"
            name="from_div"
            onChange={props.onChangeValue}
            options={props.divisionList}
            search={true}
            selection={true}
            value={props.data.from_div || ""}>
          </Dropdown>
          <div
            id="CardIntraHospitalTransferFormUX-div-7"
            style={{padding: "0px 10px", display: "flex" }}>

            <div
              id="CardIntraHospitalTransferFormUX-div-138">
              To
            </div>
            <div
              id="CardIntraHospitalTransferFormUX-div-139"
              style={{ color: "red" }}>
              *
            </div>
          </div>
          <Dropdown
            id="CardIntraHospitalTransferFormUX-Dropdown-9"
            name="to_div"
            onChange={props.onChangeValue}
            options={props.divisionList}
            search={true}
            selection={true}
            value={props.data.to_div || ""}>
          </Dropdown>
        </div>
        <div
          id="CardIntraHospitalTransferFormUX-div-207">

          <Form
            id="CardIntraHospitalTransferFormUX-Form-208">
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-209"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-212"
                width={16}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-219"
                  style={{ width: "100%" }}>

                  <div
                    id="CardIntraHospitalTransferFormUX-div-220"
                    style={{fontSize: "16px", fontWeight: "bold"}}>
                    Situation
                  </div>
                  <hr
                    id="CardIntraHospitalTransferFormUX-hr-227">
                  </hr>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-210"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-213"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-221"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Chief complain
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-218"
                inline={true}
                width="13">
                <div
                  id="CardIntraHospitalTransferFormUX-div-222"
                  style={{ width: "100%" }}>

                  <Input
                    id="CardIntraHospitalTransferFormUX-Input-228"
                    name="chief_complaint"
                    onChange={props.onChangeValue}
                    value={props.data.chief_complaint || ""}>
                  </Input>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-211"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-214"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-223"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Reason to Transfer
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-215"
                inline={true}
                width={5}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-224"
                  style={{ width: "100%" }}>

                  <Dropdown
                    fluid={true}
                    id="CardIntraHospitalTransferFormUX-Dropdown-230"
                    name="reason_to_transfer"
                    onChange={props.onChangeValue}
                    options={props.reasonToTransferChoicesOptions}
                    selection={true}
                    value={props.data.reason_to_transfer || ""}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-216"
                inline={true}
                width="3">
                <div
                  id="CardIntraHospitalTransferFormUX-div-225"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Mode of Transfer
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-217"
                inline={true}
                width={5}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-226"
                  style={{ width: "100%" }}>

                  <Dropdown
                    fluid={true}
                    id="CardIntraHospitalTransferFormUX-Dropdown-229"
                    name="mode_of_transfer"
                    onChange={props.onChangeValue}
                    options={props.modeOfTransferOptions}
                    selection={true}
                    value={props.data.mode_of_transfer || ""}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
        <div
          id="CardIntraHospitalTransferFormUX-div-140"
          style={{ marginTop: "40px" }}>

          <Form
            id="CardIntraHospitalTransferFormUX-Form-141">
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-142"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-143"
                width={16}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-169"
                  style={{ width: "100%" }}>

                  <div
                    id="CardIntraHospitalTransferFormUX-div-174"
                    style={{fontSize: "16px", fontWeight: "bold"}}>
                    Backgound
                  </div>
                  <hr
                    className={{ width: "100%" }}
                    id="CardIntraHospitalTransferFormUX-hr-175">
                  </hr>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-144"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-149"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-170"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Isolation Precaution
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-150"
                inline={true}
                width={6}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-171"
                  style={{ width: "100%" }}>

                  <Dropdown
                    clearable={true}
                    fluid={true}
                    id="CardIntraHospitalTransferFormUX-Dropdown-176"
                    multiple={true}
                    name="isolation_precaution"
                    onChange={props.onChangeValue}
                    options={props.isolationPrecautionOptions}
                    selection={true}
                    value={props.data.isolation_precaution || []}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-151"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-172"
                  style={{margin: "10px", fontWeight: "bold"}}>
                   Type of Transfer
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-152"
                inline={true}
                width={4}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-173"
                  style={{ width: "100%" }}>

                  <Dropdown
                    fluid={true}
                    id="CardIntraHospitalTransferFormUX-Dropdown-177"
                    name="type_of_transfer"
                    onChange={props.onChangeValue}
                    options={props.typeOfTransferOptions}
                    selection={true}
                    value={props.data.type_of_transfer || ""}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-145"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-153"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-178"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Pretransfer Vital Signs
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-154"
                inline={true}
                width={6}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-181"
                  style={{ display: "flex", width: "100%" }}>

                  <div
                    id="CardIntraHospitalTransferFormUX-div-182"
                    style={{margin: "10px", fontWeight: "bold", width: "50%"}}>
                     BP(mmHg)
                  </div>
                  <div
                    id="CardIntraHospitalTransferFormUX-div-183"
                    style={{ width: "60%" }}>

                    <Input
                      disabled={true}
                      id="CardIntraHospitalTransferFormUX-Input-185"
                      readOnly={true}
                      value={props.data.vital_bp || ""}>
                    </Input>
                  </div>
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-155"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-180"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Heart Rate(times/min)
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-156"
                inline={true}
                width={4}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-179"
                  style={{ width: "100%" }}>

                  <Input
                    disabled={true}
                    id="CardIntraHospitalTransferFormUX-Input-184"
                    readOnly={true}
                    value={props.data.vital_heart_rate || ""}>
                  </Input>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-146"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-157"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-186"
                  style={{ width: "80%" }}>

                  <Button
                    color="yellow"
                    fluid={true}
                    id="CardIntraHospitalTransferFormUX-Button-194"
                    onClick={props.onSelectVitalSign}>
                    Select Vital Signs
                  </Button>
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-158"
                inline={true}
                width={6}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-187"
                  style={{ display: "flex", width: "100%" }}>

                  <div
                    id="CardIntraHospitalTransferFormUX-div-188"
                    style={{margin: "10px", fontWeight: "bold", width: "50%"}}>
                    Body Temperature(oC)
                  </div>
                  <div
                    id="CardIntraHospitalTransferFormUX-div-189"
                    style={{ width: "60%" }}>

                    <Input
                      disabled={true}
                      id="CardIntraHospitalTransferFormUX-Input-193"
                      readOnly={true}
                      value={props.data.vital_body_temp || ""}>
                    </Input>
                  </div>
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-159"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-190"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Respiratory Rate(times/min)
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-160"
                inline={true}
                width={4}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-191"
                  style={{ width: "100%" }}>

                  <Input
                    disabled={true}
                    id="CardIntraHospitalTransferFormUX-Input-192"
                    readOnly={true}
                    value={props.data.vital_respiratory_rate || ""}>
                  </Input>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-147"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-165"
                inline={true}
                width={3}>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-166"
                inline={true}
                width={6}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-195"
                  style={{ display: "flex", width: "100%" }}>

                  <div
                    id="CardIntraHospitalTransferFormUX-div-196"
                    style={{margin: "10px", fontWeight: "bold", width: "50%"}}>
                    O2Sat(%)
                  </div>
                  <div
                    id="CardIntraHospitalTransferFormUX-div-197"
                    style={{ width: "60%" }}>

                    <Input
                      disabled={true}
                      id="CardIntraHospitalTransferFormUX-Input-198"
                      readOnly={true}
                      value={props.data.vital_o2_sat || ""}>
                    </Input>
                  </div>
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-167"
                inline={true}
                width={7}>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-148"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-161"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-199"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Allergy
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-162"
                inline={true}
                width={6}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-200"
                  style={{ width: "100%" }}>
                  {props.allergyText}
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-163"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-201"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Level of conciousness
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-164"
                inline={true}
                width={4}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-202"
                  style={{ width: "100%" }}>

                  <Dropdown
                    fluid={true}
                    id="CardIntraHospitalTransferFormUX-Dropdown-204"
                    name="conciousness"
                    onChange={props.onChangeValue}
                    options={props.conciousnessLevelOptions}
                    selection={true}
                    style={{height: "fit-content"}}
                    value={props.data.conciousness || ""}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
        <div
          id="CardIntraHospitalTransferFormUX-div-231"
          style={{ marginTop: "40px" }}>

          <Form
            id="CardIntraHospitalTransferFormUX-Form-233">
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-234"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-244"
                width={16}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-275"
                  style={{ width: "100%" }}>

                  <div
                    id="CardIntraHospitalTransferFormUX-div-276"
                    style={{fontSize: "16px", fontWeight: "bold"}}>
                    Assessment
                  </div>
                  <hr
                    id="CardIntraHospitalTransferFormUX-hr-277">
                  </hr>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-235"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-245"
                inline={true}
                width={2}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-278"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Oxygen In Use
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-246"
                inline={true}
                width={13}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-279">

                  <Checkbox
                    checked={props.data?.oxygen_in_use || false}
                    id="CardIntraHospitalTransferFormUX-Checkbox-296"
                    label={props.data?.oxygen_in_use ? "Yes" : "No"}
                    name="oxygen_in_use"
                    onChange={props.onChangeValue}
                    toggle={true}>
                  </Checkbox>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-236"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-247"
                inline={true}
                width={2}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-280"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Bleeding Precaution
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-248"
                inline={true}
                width={2}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-281">

                  <Checkbox
                    checked={props.data?.bleeding_precaution || false}
                    id="CardIntraHospitalTransferFormUX-Checkbox-297"
                    label={props.data?.bleeding_precaution ? "Yes" : "No"}
                    name="bleeding_precaution"
                    onChange={props.onChangeValue}
                    toggle={true}>
                  </Checkbox>
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-249"
                inline={true}
                width={2}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-282"
                  style={{ display: "flex", alignItems: "center", width: "100%" }}>

                  <div
                    id="CardIntraHospitalTransferFormUX-div-301"
                    style={{margin: "10px", fontWeight: "bold", width: "20%"}}>
                    INR
                  </div>
                  <div
                    id="CardIntraHospitalTransferFormUX-div-302"
                    style={{ width: "80%", display: "flex", justifyContent: "flex-end" }}>

                    <Input
                      disabled={!props.data?.bleeding_precaution}
                      id="CardIntraHospitalTransferFormUX-Input-310"
                      name="bleeding_precaution_inr"
                      onChange={props.onChangeValue}
                      value={props.data?.bleeding_precaution_inr || ""}>
                    </Input>
                  </div>
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-250"
                inline={true}
                width={2}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-283"
                  style={{ display: "flex", alignItems: "center", width: "100%" }}>

                  <div
                    id="CardIntraHospitalTransferFormUX-div-303"
                    style={{margin: "10px", fontWeight: "bold", width: "20%"}}>
                    Plt
                  </div>
                  <div
                    id="CardIntraHospitalTransferFormUX-div-304"
                    style={{ width: "80%" }}>

                    <Input
                      disabled={!props.data?.bleeding_precaution}
                      id="CardIntraHospitalTransferFormUX-Input-311"
                      name="bleeding_precaution_plt"
                      onChange={props.onChangeValue}
                      value={props.data?.bleeding_precaution_plt || ""}>
                    </Input>
                  </div>
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-353"
                inline={true}
                width={2}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-354"
                  style={{margin: "10px", fontWeight: "bold" }}>
                  x103/cumm
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-423"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-426"
                  style={{ display: "flex", alignItems: "center", width: "100%" }}>

                  <div
                    id="CardIntraHospitalTransferFormUX-div-427"
                    style={{margin: "10px", fontWeight: "bold", }}>
                     Hct(%)
                  </div>
                  <div
                    id="CardIntraHospitalTransferFormUX-div-428"
                    style={{ width: "60%" }}>

                    <Input
                      disabled={!props.data?.bleeding_precaution}
                      id="CardIntraHospitalTransferFormUX-Input-432"
                      name="bleeding_precaution_hct"
                      onChange={props.onChangeValue}
                      value={props.data?.bleeding_precaution_hct || ""}>
                    </Input>
                  </div>
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-424"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-425"
                  style={{ display: "flex", alignItems: "center", width: "100%" }}>

                  <div
                    id="CardIntraHospitalTransferFormUX-div-429"
                    style={{margin: "10px", fontWeight: "bold", }}>
                    Hb(g/dl)
                  </div>
                  <div
                    id="CardIntraHospitalTransferFormUX-div-430"
                    style={{ width: "60%" }}>

                    <Input
                      disabled={!props.data?.bleeding_precaution}
                      id="CardIntraHospitalTransferFormUX-Input-431"
                      name="bleeding_precaution_hb"
                      onChange={props.onChangeValue}
                      value={props.data?.bleeding_precaution_hb || ""}>
                    </Input>
                  </div>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-237"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-253"
                inline={true}
                width={2}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-286"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Protection
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-254"
                inline={true}
                width={2}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-287">

                  <Checkbox
                    checked={props.data?.protection || false}
                    id="CardIntraHospitalTransferFormUX-Checkbox-298"
                    label={props.data?.protection ? "Yes" : "No"}
                    name="protection"
                    onChange={props.onChangeValue}
                    toggle={true}>
                  </Checkbox>
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-255"
                inline={true}
                width={12}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-288"
                  style={{ width: "65%" }}>

                  <Dropdown
                    clearable={true}
                    disabled={!props.data?.protection}
                    fluid={true}
                    id="CardIntraHospitalTransferFormUX-Dropdown-314"
                    multiple={true}
                    name="protection_detail"
                    onChange={props.onChangeValue}
                    options={props.protectionOptions}
                    selection={true}
                    value={props.data.protection_detail || []}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-238"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-256"
                inline={true}
                width={2}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-289"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Fall risk
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-257"
                inline={true}
                width={13}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-290">

                  <Checkbox
                    checked={props.data?.fall_risk || false}
                    id="CardIntraHospitalTransferFormUX-Checkbox-299"
                    label={props.data?.fall_risk ? "Yes" : "No"}
                    name="fall_risk"
                    onChange={props.onChangeValue}
                    toggle={true}>
                  </Checkbox>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-239"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-258"
                inline={true}
                width={2}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-291"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Pain
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-259"
                inline={true}
                width={2}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-292">

                  <Checkbox
                    checked={props.data?.pain || false}
                    id="CardIntraHospitalTransferFormUX-Checkbox-300"
                    label={props.data?.pain ? "Yes" : "No"}
                    name="pain"
                    onChange={props.onChangeValue}
                    toggle={true}>
                  </Checkbox>
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-375"
                inline={true}
                width={4}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-433"
                  style={{margin: "10px", fontWeight: "bold" }}>
                  Pain score
                </div>
                <div
                  id="CardIntraHospitalTransferFormUX-div-376"
                  style={{ width: "70%" }}>

                  <Input
                    disabled={!props.data?.pain}
                    id="CardIntraHospitalTransferFormUX-Input-377"
                    name="pain_score"
                    onChange={props.onChangeValue}
                    value={props.data.pain_score || ""}>
                  </Input>
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-434"
                inline={true}
                style={{ display: "flex", justifyContent: "center" }}
                width={5}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-435"
                  style={{margin: "10px", fontWeight: "bold" }}>
                  Intervention
                </div>
                <div
                  id="CardIntraHospitalTransferFormUX-div-436"
                  style={{ marginRight: "20px" }}>

                  <Checkbox
                    checked={props.data?.intervention || false}
                    id="CardIntraHospitalTransferFormUX-Checkbox-437"
                    label={props.data?.intervention ? "Yes" : "NO"}
                    name="intervention"
                    onChange={props.onChangeValue}
                    toggle={true}>
                  </Checkbox>
                </div>
                <div
                  id="CardIntraHospitalTransferFormUX-div-441"
                  style={{ width: "100%" }}>

                  <Input
                    disabled={!props.data?.intervention}
                    id="CardIntraHospitalTransferFormUX-Input-442"
                    name="intervention_detail"
                    onChange={props.onChangeValue}
                    value={props.data.intervention_detail || ""}>
                  </Input>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-240"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-263"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-325"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Special Care
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-264"
                inline={true}
                width={5}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-326"
                  style={{ width: "100%" }}>

                  <Dropdown
                    clearable={true}
                    fluid={true}
                    id="CardIntraHospitalTransferFormUX-Dropdown-337"
                    multiple={true}
                    name="special_care"
                    onChange={props.onChangeValue}
                    options={props.specialCareOptions}
                    selection={true}
                    value={props.data.special_care || []}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-241"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-265"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-327"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Medication
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-266"
                inline={true}
                width={5}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-328"
                  style={{ width: "100%" }}>

                  <Input
                    fluid={true}
                    id="CardIntraHospitalTransferFormUX-Input-338"
                    name="medication"
                    onChange={props.onChangeValue}
                    value={props.data.medication || ""}>
                  </Input>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-242"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-267"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-329"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Special equipment come with patient
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-268"
                inline={true}
                width={5}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-330"
                  style={{ width: "100%" }}>

                  <Input
                    fluid={true}
                    id="CardIntraHospitalTransferFormUX-Input-339"
                    name="special_equipment_come_with_patient"
                    onChange={props.onChangeValue}
                    value={props.data.special_equipment_come_with_patient || ""}>
                  </Input>
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-269"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-331"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Special equipment to be prepared
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-270"
                inline={true}
                width={5}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-332"
                  style={{ width: "100%" }}>

                  <Input
                    fluid={true}
                    id="CardIntraHospitalTransferFormUX-Input-340"
                    name="special_equipment_to_be_prepared"
                    onChange={props.onChangeValue}
                    value={props.data.special_equipment_to_be_prepared || ""}>
                  </Input>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-243"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-271"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-333"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  valuable
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-272"
                inline={true}
                width={5}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-334"
                  style={{ width: "100%" }}>

                  <Input
                    fluid={true}
                    id="CardIntraHospitalTransferFormUX-Input-342"
                    name="valuable"
                    onChange={props.onChangeValue}
                    value={props.data.valuable || ""}>
                  </Input>
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-273"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-335"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Other
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-274"
                inline={true}
                width={5}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-336"
                  style={{ width: "100%" }}>

                  <Input
                    fluid={true}
                    id="CardIntraHospitalTransferFormUX-Input-341"
                    name="other"
                    onChange={props.onChangeValue}
                    value={props.data.other || ""}>
                  </Input>
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
        <div
          id="CardIntraHospitalTransferFormUX-div-232"
          style={{ marginTop: "40px" }}>

          <Form
            id="CardIntraHospitalTransferFormUX-Form-378">
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-379"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-384"
                inline={true}
                width={16}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-401"
                  style={{ width: "100%" }}>

                  <div
                    id="CardIntraHospitalTransferFormUX-div-402"
                    style={{fontSize: "16px", fontWeight: "bold"}}>
                    Recommendations
                  </div>
                  <hr
                    id="CardIntraHospitalTransferFormUX-hr-403">
                  </hr>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-380"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-387"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-404"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Post-transfer Assessment(At arrival)
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-388"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-408">

                  <Checkbox
                    checked={props.data.post_transfer_assessment || false}
                    id="CardIntraHospitalTransferFormUX-Checkbox-419"
                    label={props.data?.post_transfer_assessment ? "Change Notify" : "No change"}
                    name="post_transfer_assessment"
                    onChange={props.onChangeValue}
                    toggle={true}>
                  </Checkbox>
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-389"
                inline={true}
                width={4}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-409"
                  style={{ width: "100%" }}>

                  <Input
                    disabled={!props.data.post_transfer_assessment }
                    id="CardIntraHospitalTransferFormUX-Input-420"
                    name="post_transfer_assessment_detail"
                    onChange={props.onChangeValue}
                    value={props.data.post_transfer_assessment_detail || ""}>
                  </Input>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-381"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-391"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-405"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Transferring Personnel
                </div>
                <div
                  id="CardIntraHospitalTransferFormUX-div-418"
                  style={{ color: "red" }}>
                  *
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-392"
                inline={true}
                width={4}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-411"
                  style={{ width: "100%" }}>
                  {props.transferringEmployee}
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-393"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-412"
                  style={{ width: "100%" }}>
                  {props.transferringTimeBox}
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-394"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-413"
                  style={{ width: "100%"}}>

                  <DateTextBox
                    id="CardIntraHospitalTransferFormUX-DateTextBox-422"
                    onChange={(date: string)=> props.onChangeValue(null, {value: date, name: "transferring_personnel_date"})}
                    value={props.data.transferring_personnel_date || ""}>
                  </DateTextBox>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-382"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-395"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-406"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  Receiving Personnel
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-396"
                inline={true}
                width={4}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-414"
                  style={{ width: "100%" }}>
                  {props.receivingEmployee}
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-399"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-415"
                  style={{ width: "100%" }}>
                  {props.receivingTimeBox}
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-400"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-416"
                  style={{ width: "100%"}}>

                  <DateTextBox
                    id="CardIntraHospitalTransferFormUX-DateTextBox-421"
                    onChange={(date: string)=> props.onChangeValue(null, {value: date, name: "receiving_personnel_date"})}
                    value={props.data.receiving_personnel_date || ""}>
                  </DateTextBox>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="CardIntraHospitalTransferFormUX-FormGroup-383"
              inline={true}>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-397"
                inline={true}
                width={3}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-407"
                  style={{margin: "10px", fontWeight: "bold"}}>
                  แพทย์เจ้าของไข้
                </div>
              </FormField>
              <FormField
                id="CardIntraHospitalTransferFormUX-FormField-398"
                inline={true}
                width={4}>
                <div
                  id="CardIntraHospitalTransferFormUX-div-417"
                  style={{ width: "100%" }}>
                  {props.patientDoctor}
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
        <div
          id="CardIntraHospitalTransferFormUX-div-130"
          style={{padding: "10px", display: "flex", justifyContent: "flex-end"}}>

          <div
            id="CardIntraHospitalTransferFormUX-div-131">
            {props.actionButton}
          </div>
          <Button
            color="blue"
            disabled={props.disablePrint}
            id="CardIntraHospitalTransferFormUX-Button-132"
            onClick={props.onPrint}
            style={{width: "200px"}}>
            พิมพ์
          </Button>
        </div>
      </div>
    )
}


export default CardIntraHospitalTransferFormUX

export const screenPropsDefault = {}

/* Date Time : Mon Mar 24 2025 12:55:38 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-0"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\",padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-1"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", alignItems: \"center\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "From"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-6"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 15px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-7"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 10px\", display: \"flex\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Dropdown",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Dropdown-8"
        },
        "name": {
          "type": "value",
          "value": "from_div"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.divisionList"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.from_div || \"\""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Dropdown",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Dropdown-9"
        },
        "name": {
          "type": "value",
          "value": "to_div"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.divisionList"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.to_div || \"\""
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-130"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 232,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 130,
      "props": {
        "children": {
          "type": "code",
          "value": "props.actionButton"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-131"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "Button",
      "parent": 130,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disablePrint"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Button-132"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrint"
        },
        "style": {
          "type": "code",
          "value": "{width: \"200px\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "To"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-138"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-139"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-140"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"40px\" }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "Form",
      "parent": 140,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Form-141"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "FormGroup",
      "parent": 141,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-142"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormField",
      "parent": 142,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-143"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "FormGroup",
      "parent": 141,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-144"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "FormGroup",
      "parent": 141,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-145"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "FormGroup",
      "parent": 141,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-146"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "FormGroup",
      "parent": 141,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-147"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "FormGroup",
      "parent": 141,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-148"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "FormField",
      "parent": 144,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-149"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "FormField",
      "parent": 144,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-150"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "FormField",
      "parent": 144,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-151"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "FormField",
      "parent": 144,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-152"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "FormField",
      "parent": 145,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-153"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "FormField",
      "parent": 145,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-154"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 155,
      "name": "FormField",
      "parent": 145,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-155"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "FormField",
      "parent": 145,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-156"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "FormField",
      "parent": 146,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-157"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "FormField",
      "parent": 146,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-158"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "FormField",
      "parent": 146,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-159"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "FormField",
      "parent": 146,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-160"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "FormField",
      "parent": 148,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-161"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "FormField",
      "parent": 148,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-162"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "FormField",
      "parent": 148,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-163"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "FormField",
      "parent": 148,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-164"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "FormField",
      "parent": 147,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-165"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "FormField",
      "parent": 147,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-166"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "FormField",
      "parent": 147,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-167"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 143,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-169"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": "Isolation Precaution"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-170"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-171"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "div",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": " Type of Transfer"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-172"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-173"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "div",
      "parent": 169,
      "props": {
        "children": {
          "type": "value",
          "value": "Backgound"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-174"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"16px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "hr",
      "parent": 169,
      "props": {
        "className": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-hr-175"
        }
      },
      "seq": 175,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "Dropdown",
      "parent": 171,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Dropdown-176"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "isolation_precaution"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.isolationPrecautionOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.isolation_precaution || []"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "Dropdown",
      "parent": 173,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Dropdown-177"
        },
        "name": {
          "type": "value",
          "value": "type_of_transfer"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.typeOfTransferOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.type_of_transfer || \"\""
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "div",
      "parent": 153,
      "props": {
        "children": {
          "type": "value",
          "value": "Pretransfer Vital Signs"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-178"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-179"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "value",
          "value": "Heart Rate(times/min)"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-180"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-181"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\" }"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 181,
      "props": {
        "children": {
          "type": "value",
          "value": " BP(mmHg)"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-182"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\", width: \"50%\"}"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 181,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-183"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"60%\" }"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "Input",
      "parent": 179,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Input-184"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.vital_heart_rate || \"\""
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "Input",
      "parent": 183,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Input-185"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.vital_bp || \"\""
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 186,
      "name": "div",
      "parent": 157,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-186"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\" }"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 187,
      "name": "div",
      "parent": 158,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-187"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\" }"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "div",
      "parent": 187,
      "props": {
        "children": {
          "type": "value",
          "value": "Body Temperature(oC)"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-188"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\", width: \"50%\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 187,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-189"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"60%\" }"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "div",
      "parent": 159,
      "props": {
        "children": {
          "type": "value",
          "value": "Respiratory Rate(times/min)"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-190"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "div",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-191"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 192,
      "name": "Input",
      "parent": 191,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Input-192"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.vital_respiratory_rate || \"\""
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 193,
      "name": "Input",
      "parent": 189,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Input-193"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.vital_body_temp || \"\""
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 194,
      "name": "Button",
      "parent": 186,
      "props": {
        "children": {
          "type": "value",
          "value": "Select Vital Signs"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Button-194"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectVitalSign"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": null,
      "id": 195,
      "name": "div",
      "parent": 166,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-195"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\" }"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": null,
      "id": 196,
      "name": "div",
      "parent": 195,
      "props": {
        "children": {
          "type": "value",
          "value": "O2Sat(%)"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-196"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\", width: \"50%\"}"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": null,
      "id": 197,
      "name": "div",
      "parent": 195,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-197"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"60%\" }"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "Input",
      "parent": 197,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Input-198"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.vital_o2_sat || \"\""
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": null,
      "id": 199,
      "name": "div",
      "parent": 161,
      "props": {
        "children": {
          "type": "value",
          "value": "Allergy"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-199"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": null,
      "id": 200,
      "name": "div",
      "parent": 162,
      "props": {
        "children": {
          "type": "code",
          "value": "props.allergyText"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-200"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": null,
      "id": 201,
      "name": "div",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": "Level of conciousness"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-201"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": null,
      "id": 202,
      "name": "div",
      "parent": 164,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-202"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 204,
      "name": "Dropdown",
      "parent": 202,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Dropdown-204"
        },
        "name": {
          "type": "value",
          "value": "conciousness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.conciousnessLevelOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height: \"fit-content\"}"
        },
        "value": {
          "type": "code",
          "value": "props.data.conciousness || \"\""
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": null,
      "id": 207,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-207"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 208,
      "name": "Form",
      "parent": 207,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Form-208"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 209,
      "name": "FormGroup",
      "parent": 208,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-209"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 210,
      "name": "FormGroup",
      "parent": 208,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-210"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 211,
      "name": "FormGroup",
      "parent": 208,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-211"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 212,
      "name": "FormField",
      "parent": 209,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-212"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 213,
      "name": "FormField",
      "parent": 210,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-213"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 214,
      "name": "FormField",
      "parent": 211,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-214"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 215,
      "name": "FormField",
      "parent": 211,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-215"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 216,
      "name": "FormField",
      "parent": 211,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-216"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 217,
      "name": "FormField",
      "parent": 211,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-217"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 218,
      "name": "FormField",
      "parent": 210,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-218"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "13"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": null,
      "id": 219,
      "name": "div",
      "parent": 212,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-219"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": null,
      "id": 220,
      "name": "div",
      "parent": 219,
      "props": {
        "children": {
          "type": "value",
          "value": "Situation"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-220"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"16px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 220,
      "void": false
    },
    {
      "from": null,
      "id": 221,
      "name": "div",
      "parent": 213,
      "props": {
        "children": {
          "type": "value",
          "value": "Chief complain"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-221"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": null,
      "id": 222,
      "name": "div",
      "parent": 218,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-222"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": null,
      "id": 223,
      "name": "div",
      "parent": 214,
      "props": {
        "children": {
          "type": "value",
          "value": "Reason to Transfer"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-223"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": null,
      "id": 224,
      "name": "div",
      "parent": 215,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-224"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": null,
      "id": 225,
      "name": "div",
      "parent": 216,
      "props": {
        "children": {
          "type": "value",
          "value": "Mode of Transfer"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-225"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": null,
      "id": 226,
      "name": "div",
      "parent": 217,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-226"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": null,
      "id": 227,
      "name": "hr",
      "parent": 219,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-hr-227"
        }
      },
      "seq": 227,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 228,
      "name": "Input",
      "parent": 222,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Input-228"
        },
        "name": {
          "type": "value",
          "value": "chief_complaint"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data.chief_complaint || \"\""
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 229,
      "name": "Dropdown",
      "parent": 226,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Dropdown-229"
        },
        "name": {
          "type": "value",
          "value": "mode_of_transfer"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.modeOfTransferOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.mode_of_transfer || \"\""
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 230,
      "name": "Dropdown",
      "parent": 224,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Dropdown-230"
        },
        "name": {
          "type": "value",
          "value": "reason_to_transfer"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.reasonToTransferChoicesOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.reason_to_transfer || \"\""
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": null,
      "id": 231,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-231"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"40px\" }"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 232,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-232"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"40px\" }"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 233,
      "name": "Form",
      "parent": 231,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Form-233"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 234,
      "name": "FormGroup",
      "parent": 233,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-234"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 235,
      "name": "FormGroup",
      "parent": 233,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-235"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 236,
      "name": "FormGroup",
      "parent": 233,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-236"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 237,
      "name": "FormGroup",
      "parent": 233,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-237"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 238,
      "name": "FormGroup",
      "parent": 233,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-238"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 239,
      "name": "FormGroup",
      "parent": 233,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-239"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 240,
      "name": "FormGroup",
      "parent": 233,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-240"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 241,
      "name": "FormGroup",
      "parent": 233,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-241"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 243,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 242,
      "name": "FormGroup",
      "parent": 233,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-242"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 343,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 243,
      "name": "FormGroup",
      "parent": 233,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-243"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 355,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 244,
      "name": "FormField",
      "parent": 234,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-244"
        },
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 245,
      "name": "FormField",
      "parent": 235,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-245"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 246,
      "name": "FormField",
      "parent": 235,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-246"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 247,
      "name": "FormField",
      "parent": 236,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-247"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 248,
      "name": "FormField",
      "parent": 236,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-248"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 249,
      "name": "FormField",
      "parent": 236,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-249"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 250,
      "name": "FormField",
      "parent": 236,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-250"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 353,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 253,
      "name": "FormField",
      "parent": 237,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-253"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 254,
      "name": "FormField",
      "parent": 237,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-254"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 255,
      "name": "FormField",
      "parent": 237,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-255"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 256,
      "name": "FormField",
      "parent": 238,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-256"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 257,
      "name": "FormField",
      "parent": 238,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-257"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 258,
      "name": "FormField",
      "parent": 239,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-258"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 258,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 259,
      "name": "FormField",
      "parent": 239,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-259"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 263,
      "name": "FormField",
      "parent": 240,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-263"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 264,
      "name": "FormField",
      "parent": 240,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-264"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 264,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 265,
      "name": "FormField",
      "parent": 241,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-265"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 266,
      "name": "FormField",
      "parent": 241,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-266"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 267,
      "name": "FormField",
      "parent": 242,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-267"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 268,
      "name": "FormField",
      "parent": 242,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-268"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 268,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 269,
      "name": "FormField",
      "parent": 242,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-269"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 270,
      "name": "FormField",
      "parent": 242,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-270"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 271,
      "name": "FormField",
      "parent": 243,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-271"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 271,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 272,
      "name": "FormField",
      "parent": 243,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-272"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 273,
      "name": "FormField",
      "parent": 243,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-273"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 273,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 274,
      "name": "FormField",
      "parent": 243,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-274"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": null,
      "id": 275,
      "name": "div",
      "parent": 244,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-275"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": null,
      "id": 276,
      "name": "div",
      "parent": 275,
      "props": {
        "children": {
          "type": "value",
          "value": "Assessment"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-276"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"16px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": null,
      "id": 277,
      "name": "hr",
      "parent": 275,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-hr-277"
        }
      },
      "seq": 277,
      "void": true
    },
    {
      "from": null,
      "id": 278,
      "name": "div",
      "parent": 245,
      "props": {
        "children": {
          "type": "value",
          "value": "Oxygen In Use"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-278"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 278,
      "void": false
    },
    {
      "from": null,
      "id": 279,
      "name": "div",
      "parent": 246,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-279"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": null,
      "id": 280,
      "name": "div",
      "parent": 247,
      "props": {
        "children": {
          "type": "value",
          "value": "Bleeding Precaution"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-280"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": null,
      "id": 281,
      "name": "div",
      "parent": 248,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-281"
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": null,
      "id": 282,
      "name": "div",
      "parent": 249,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-282"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", width: \"100%\" }"
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": null,
      "id": 283,
      "name": "div",
      "parent": 250,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-283"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", width: \"100%\" }"
        }
      },
      "seq": 283,
      "void": false
    },
    {
      "from": null,
      "id": 286,
      "name": "div",
      "parent": 253,
      "props": {
        "children": {
          "type": "value",
          "value": "Protection"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-286"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": null,
      "id": 287,
      "name": "div",
      "parent": 254,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-287"
        }
      },
      "seq": 287,
      "void": false
    },
    {
      "from": null,
      "id": 288,
      "name": "div",
      "parent": 255,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-288"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"65%\" }"
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": null,
      "id": 289,
      "name": "div",
      "parent": 256,
      "props": {
        "children": {
          "type": "value",
          "value": "Fall risk"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-289"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": null,
      "id": 290,
      "name": "div",
      "parent": 257,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-290"
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": null,
      "id": 291,
      "name": "div",
      "parent": 258,
      "props": {
        "children": {
          "type": "value",
          "value": "Pain"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-291"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": null,
      "id": 292,
      "name": "div",
      "parent": 259,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-292"
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 296,
      "name": "Checkbox",
      "parent": 279,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.oxygen_in_use || false"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Checkbox-296"
        },
        "label": {
          "type": "code",
          "value": "props.data?.oxygen_in_use ? \"Yes\" : \"No\""
        },
        "name": {
          "type": "value",
          "value": "oxygen_in_use"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 296,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 297,
      "name": "Checkbox",
      "parent": 281,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.bleeding_precaution || false"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Checkbox-297"
        },
        "label": {
          "type": "code",
          "value": "props.data?.bleeding_precaution ? \"Yes\" : \"No\""
        },
        "name": {
          "type": "value",
          "value": "bleeding_precaution"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 297,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 298,
      "name": "Checkbox",
      "parent": 287,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.protection || false"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Checkbox-298"
        },
        "label": {
          "type": "code",
          "value": "props.data?.protection ? \"Yes\" : \"No\""
        },
        "name": {
          "type": "value",
          "value": "protection"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 298,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 299,
      "name": "Checkbox",
      "parent": 290,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.fall_risk || false"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Checkbox-299"
        },
        "label": {
          "type": "code",
          "value": "props.data?.fall_risk ? \"Yes\" : \"No\""
        },
        "name": {
          "type": "value",
          "value": "fall_risk"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 300,
      "name": "Checkbox",
      "parent": 292,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.pain || false"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Checkbox-300"
        },
        "label": {
          "type": "code",
          "value": "props.data?.pain ? \"Yes\" : \"No\""
        },
        "name": {
          "type": "value",
          "value": "pain"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": null,
      "id": 301,
      "name": "div",
      "parent": 282,
      "props": {
        "children": {
          "type": "value",
          "value": "INR"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-301"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\", width: \"20%\"}"
        }
      },
      "seq": 301,
      "void": false
    },
    {
      "from": null,
      "id": 302,
      "name": "div",
      "parent": 282,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-302"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\", display: \"flex\", justifyContent: \"flex-end\" }"
        }
      },
      "seq": 302,
      "void": false
    },
    {
      "from": null,
      "id": 303,
      "name": "div",
      "parent": 283,
      "props": {
        "children": {
          "type": "value",
          "value": "Plt"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-303"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\", width: \"20%\"}"
        }
      },
      "seq": 303,
      "void": false
    },
    {
      "from": null,
      "id": 304,
      "name": "div",
      "parent": 283,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-304"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\" }"
        }
      },
      "seq": 304,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 310,
      "name": "Input",
      "parent": 302,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.data?.bleeding_precaution"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Input-310"
        },
        "name": {
          "type": "value",
          "value": "bleeding_precaution_inr"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data?.bleeding_precaution_inr || \"\""
        }
      },
      "seq": 310,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 311,
      "name": "Input",
      "parent": 304,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.data?.bleeding_precaution"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Input-311"
        },
        "name": {
          "type": "value",
          "value": "bleeding_precaution_plt"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data?.bleeding_precaution_plt || \"\""
        }
      },
      "seq": 311,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 314,
      "name": "Dropdown",
      "parent": 288,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.data?.protection"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Dropdown-314"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "protection_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.protectionOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.protection_detail || []"
        }
      },
      "seq": 314,
      "void": false
    },
    {
      "from": null,
      "id": 325,
      "name": "div",
      "parent": 263,
      "props": {
        "children": {
          "type": "value",
          "value": "Special Care"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-325"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 325,
      "void": false
    },
    {
      "from": null,
      "id": 326,
      "name": "div",
      "parent": 264,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-326"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 326,
      "void": false
    },
    {
      "from": null,
      "id": 327,
      "name": "div",
      "parent": 265,
      "props": {
        "children": {
          "type": "value",
          "value": "Medication"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-327"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 327,
      "void": false
    },
    {
      "from": null,
      "id": 328,
      "name": "div",
      "parent": 266,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-328"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 328,
      "void": false
    },
    {
      "from": null,
      "id": 329,
      "name": "div",
      "parent": 267,
      "props": {
        "children": {
          "type": "value",
          "value": "Special equipment come with patient"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-329"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 329,
      "void": false
    },
    {
      "from": null,
      "id": 330,
      "name": "div",
      "parent": 268,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-330"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 330,
      "void": false
    },
    {
      "from": null,
      "id": 331,
      "name": "div",
      "parent": 269,
      "props": {
        "children": {
          "type": "value",
          "value": "Special equipment to be prepared"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-331"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 331,
      "void": false
    },
    {
      "from": null,
      "id": 332,
      "name": "div",
      "parent": 270,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-332"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 332,
      "void": false
    },
    {
      "from": null,
      "id": 333,
      "name": "div",
      "parent": 271,
      "props": {
        "children": {
          "type": "value",
          "value": "valuable"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-333"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 333,
      "void": false
    },
    {
      "from": null,
      "id": 334,
      "name": "div",
      "parent": 272,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-334"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 334,
      "void": false
    },
    {
      "from": null,
      "id": 335,
      "name": "div",
      "parent": 273,
      "props": {
        "children": {
          "type": "value",
          "value": "Other"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-335"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 335,
      "void": false
    },
    {
      "from": null,
      "id": 336,
      "name": "div",
      "parent": 274,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-336"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 336,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 337,
      "name": "Dropdown",
      "parent": 326,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Dropdown-337"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "special_care"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.specialCareOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data.special_care || []"
        }
      },
      "seq": 337,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 338,
      "name": "Input",
      "parent": 328,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Input-338"
        },
        "name": {
          "type": "value",
          "value": "medication"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data.medication || \"\""
        }
      },
      "seq": 338,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 339,
      "name": "Input",
      "parent": 330,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Input-339"
        },
        "name": {
          "type": "value",
          "value": "special_equipment_come_with_patient"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data.special_equipment_come_with_patient || \"\""
        }
      },
      "seq": 339,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 340,
      "name": "Input",
      "parent": 332,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Input-340"
        },
        "name": {
          "type": "value",
          "value": "special_equipment_to_be_prepared"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data.special_equipment_to_be_prepared || \"\""
        }
      },
      "seq": 340,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 341,
      "name": "Input",
      "parent": 336,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Input-341"
        },
        "name": {
          "type": "value",
          "value": "other"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data.other || \"\""
        }
      },
      "seq": 341,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 342,
      "name": "Input",
      "parent": 334,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Input-342"
        },
        "name": {
          "type": "value",
          "value": "valuable"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data.valuable || \"\""
        }
      },
      "seq": 342,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 353,
      "name": "FormField",
      "parent": 236,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-353"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 354,
      "void": false
    },
    {
      "from": null,
      "id": 354,
      "name": "div",
      "parent": 353,
      "props": {
        "children": {
          "type": "value",
          "value": "x103/cumm"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-354"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 354,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 375,
      "name": "FormField",
      "parent": 239,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-375"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 375,
      "void": false
    },
    {
      "from": null,
      "id": 376,
      "name": "div",
      "parent": 375,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-376"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"70%\" }"
        }
      },
      "seq": 433,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 377,
      "name": "Input",
      "parent": 376,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.data?.pain"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Input-377"
        },
        "name": {
          "type": "value",
          "value": "pain_score"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data.pain_score || \"\""
        }
      },
      "seq": 377,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 378,
      "name": "Form",
      "parent": 232,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Form-378"
        }
      },
      "seq": 378,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 379,
      "name": "FormGroup",
      "parent": 378,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-379"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 379,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 380,
      "name": "FormGroup",
      "parent": 378,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-380"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 380,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 381,
      "name": "FormGroup",
      "parent": 378,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-381"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 381,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 382,
      "name": "FormGroup",
      "parent": 378,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-382"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 382,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 383,
      "name": "FormGroup",
      "parent": 378,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormGroup-383"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 383,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 384,
      "name": "FormField",
      "parent": 379,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-384"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 384,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 387,
      "name": "FormField",
      "parent": 380,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-387"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 387,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 388,
      "name": "FormField",
      "parent": 380,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-388"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 388,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 389,
      "name": "FormField",
      "parent": 380,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-389"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 389,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 391,
      "name": "FormField",
      "parent": 381,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-391"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 391,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 392,
      "name": "FormField",
      "parent": 381,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-392"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 392,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 393,
      "name": "FormField",
      "parent": 381,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-393"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 393,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 394,
      "name": "FormField",
      "parent": 381,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-394"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 394,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 395,
      "name": "FormField",
      "parent": 382,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-395"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 395,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 396,
      "name": "FormField",
      "parent": 382,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-396"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 396,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 397,
      "name": "FormField",
      "parent": 383,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-397"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 397,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 398,
      "name": "FormField",
      "parent": 383,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-398"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 398,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 399,
      "name": "FormField",
      "parent": 382,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-399"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 399,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 400,
      "name": "FormField",
      "parent": 382,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-400"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 400,
      "void": false
    },
    {
      "from": null,
      "id": 401,
      "name": "div",
      "parent": 384,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-401"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 401,
      "void": false
    },
    {
      "from": null,
      "id": 402,
      "name": "div",
      "parent": 401,
      "props": {
        "children": {
          "type": "value",
          "value": "Recommendations"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-402"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"16px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 402,
      "void": false
    },
    {
      "from": null,
      "id": 403,
      "name": "hr",
      "parent": 401,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-hr-403"
        }
      },
      "seq": 403,
      "void": true
    },
    {
      "from": null,
      "id": 404,
      "name": "div",
      "parent": 387,
      "props": {
        "children": {
          "type": "value",
          "value": "Post-transfer Assessment(At arrival)"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-404"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 404,
      "void": false
    },
    {
      "from": null,
      "id": 405,
      "name": "div",
      "parent": 391,
      "props": {
        "children": {
          "type": "value",
          "value": "Transferring Personnel"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-405"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 405,
      "void": false
    },
    {
      "from": null,
      "id": 406,
      "name": "div",
      "parent": 395,
      "props": {
        "children": {
          "type": "value",
          "value": "Receiving Personnel"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-406"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 406,
      "void": false
    },
    {
      "from": null,
      "id": 407,
      "name": "div",
      "parent": 397,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์เจ้าของไข้"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-407"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 407,
      "void": false
    },
    {
      "from": null,
      "id": 408,
      "name": "div",
      "parent": 388,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-408"
        }
      },
      "seq": 408,
      "void": false
    },
    {
      "from": null,
      "id": 409,
      "name": "div",
      "parent": 389,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-409"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 409,
      "void": false
    },
    {
      "from": null,
      "id": 411,
      "name": "div",
      "parent": 392,
      "props": {
        "children": {
          "type": "code",
          "value": "props.transferringEmployee"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-411"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 411,
      "void": false
    },
    {
      "from": null,
      "id": 412,
      "name": "div",
      "parent": 393,
      "props": {
        "children": {
          "type": "code",
          "value": "props.transferringTimeBox"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-412"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 412,
      "void": false
    },
    {
      "from": null,
      "id": 413,
      "name": "div",
      "parent": 394,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-413"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\"}"
        }
      },
      "seq": 413,
      "void": false
    },
    {
      "from": null,
      "id": 414,
      "name": "div",
      "parent": 396,
      "props": {
        "children": {
          "type": "code",
          "value": "props.receivingEmployee"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-414"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 414,
      "void": false
    },
    {
      "from": null,
      "id": 415,
      "name": "div",
      "parent": 399,
      "props": {
        "children": {
          "type": "code",
          "value": "props.receivingTimeBox"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-415"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 415,
      "void": false
    },
    {
      "from": null,
      "id": 416,
      "name": "div",
      "parent": 400,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-416"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\"}"
        }
      },
      "seq": 416,
      "void": false
    },
    {
      "from": null,
      "id": 417,
      "name": "div",
      "parent": 398,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientDoctor"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-417"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 417,
      "void": false
    },
    {
      "from": null,
      "id": 418,
      "name": "div",
      "parent": 391,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-418"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 418,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 419,
      "name": "Checkbox",
      "parent": 408,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data.post_transfer_assessment || false"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Checkbox-419"
        },
        "label": {
          "type": "code",
          "value": "props.data?.post_transfer_assessment ? \"Change Notify\" : \"No change\""
        },
        "name": {
          "type": "value",
          "value": "post_transfer_assessment"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 419,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 420,
      "name": "Input",
      "parent": 409,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.data.post_transfer_assessment "
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Input-420"
        },
        "name": {
          "type": "value",
          "value": "post_transfer_assessment_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data.post_transfer_assessment_detail || \"\""
        }
      },
      "seq": 420,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 421,
      "name": "DateTextBox",
      "parent": 416,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-DateTextBox-421"
        },
        "onChange": {
          "type": "code",
          "value": "(date: string)=> props.onChangeValue(null, {value: date, name: \"receiving_personnel_date\"})"
        },
        "value": {
          "type": "code",
          "value": "props.data.receiving_personnel_date || \"\""
        }
      },
      "seq": 421,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 422,
      "name": "DateTextBox",
      "parent": 413,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-DateTextBox-422"
        },
        "onChange": {
          "type": "code",
          "value": "(date: string)=> props.onChangeValue(null, {value: date, name: \"transferring_personnel_date\"})"
        },
        "value": {
          "type": "code",
          "value": "props.data.transferring_personnel_date || \"\""
        }
      },
      "seq": 422,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 423,
      "name": "FormField",
      "parent": 236,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-423"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 423,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 424,
      "name": "FormField",
      "parent": 236,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-424"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 424,
      "void": false
    },
    {
      "from": null,
      "id": 425,
      "name": "div",
      "parent": 424,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-425"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", width: \"100%\" }"
        }
      },
      "seq": 425,
      "void": false
    },
    {
      "from": null,
      "id": 426,
      "name": "div",
      "parent": 423,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-426"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", width: \"100%\" }"
        }
      },
      "seq": 426,
      "void": false
    },
    {
      "from": null,
      "id": 427,
      "name": "div",
      "parent": 426,
      "props": {
        "children": {
          "type": "value",
          "value": " Hct(%)"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-427"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\", }"
        }
      },
      "seq": 427,
      "void": false
    },
    {
      "from": null,
      "id": 428,
      "name": "div",
      "parent": 426,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-428"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"60%\" }"
        }
      },
      "seq": 428,
      "void": false
    },
    {
      "from": null,
      "id": 429,
      "name": "div",
      "parent": 425,
      "props": {
        "children": {
          "type": "value",
          "value": "Hb(g/dl)"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-429"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\", }"
        }
      },
      "seq": 429,
      "void": false
    },
    {
      "from": null,
      "id": 430,
      "name": "div",
      "parent": 425,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-430"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"60%\" }"
        }
      },
      "seq": 430,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 431,
      "name": "Input",
      "parent": 430,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.data?.bleeding_precaution"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Input-431"
        },
        "name": {
          "type": "value",
          "value": "bleeding_precaution_hb"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data?.bleeding_precaution_hb || \"\""
        }
      },
      "seq": 431,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 432,
      "name": "Input",
      "parent": 428,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.data?.bleeding_precaution"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Input-432"
        },
        "name": {
          "type": "value",
          "value": "bleeding_precaution_hct"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data?.bleeding_precaution_hct || \"\""
        }
      },
      "seq": 432,
      "void": false
    },
    {
      "from": null,
      "id": 433,
      "name": "div",
      "parent": 375,
      "props": {
        "children": {
          "type": "value",
          "value": "Pain score"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-433"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 376,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 434,
      "name": "FormField",
      "parent": 239,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-FormField-434"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 434,
      "void": false
    },
    {
      "from": null,
      "id": 435,
      "name": "div",
      "parent": 434,
      "props": {
        "children": {
          "type": "value",
          "value": "Intervention"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-435"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 435,
      "void": false
    },
    {
      "from": null,
      "id": 436,
      "name": "div",
      "parent": 434,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-436"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        }
      },
      "seq": 436,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 437,
      "name": "Checkbox",
      "parent": 436,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.data?.intervention || false"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Checkbox-437"
        },
        "label": {
          "type": "code",
          "value": "props.data?.intervention ? \"Yes\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "intervention"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 437,
      "void": false
    },
    {
      "from": null,
      "id": 441,
      "name": "div",
      "parent": 434,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-div-441"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 441,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 442,
      "name": "Input",
      "parent": 441,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.data?.intervention"
        },
        "id": {
          "type": "value",
          "value": "CardIntraHospitalTransferFormUX-Input-442"
        },
        "name": {
          "type": "value",
          "value": "intervention_detail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.data.intervention_detail || \"\""
        }
      },
      "seq": 442,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardIntraHospitalTransferFormUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
